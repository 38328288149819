.past-programs-header {
  height: 124px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  position: relative;
  .past-programs-text {
    position: absolute;
    top: 76px;
    left: 48px;
    line-height: 24px;
  }
}

.past-program-card {
  border: 1px solid #ededed;
  border-radius: 6px;
  margin: 24px 15px;
  position: relative;
  .past-program-container {
    padding: 16px;
    .program-top-card-content {
      min-height: 22px;
      .program-card-title {
        margin-left: 14px;
        line-height: 20px;
      }
    }
    .program-card-label {
      font-weight: 300;
      font-size: 12px;
      line-height: 13px;
      color: #6f6f6f;
      margin-bottom: 4px;
    }
    .program-value {
      color: #313131;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }
  }
}
