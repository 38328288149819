.instruction-container {
  height: 220px;
  border: 1px solid $unicorn-silver-color;
  border-radius: 6px;
  .instruction-header {
    background-color: #ffefef;
    height: 64px;
  }
}
.start-program-btn {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50px;
  background-color: $indigo-blue;
  color: $white-color;
  border-radius: 6px;
  border: 1px solid $indigo-blue;
}
