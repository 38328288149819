.vital-header {
  height: 124px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  position: relative;
  .vital-text {
    position: absolute;
    top: 76px;
    left: 48px;
    line-height: 24px;
  }
  .info-icon {
    position: absolute;
    top: 74px;
    right: 24px;
    cursor: pointer;
  }
}

.red {
  border: 1px solid #ffc6ca;
}

.blue {
  border: 1px solid #83cbd8;
}

.violet {
  border: 1px solid #939df4;
}

.gray {
  border: 1px solid rgba(49, 49, 49, 0.1);
}

.track-card {
  border-radius: 8px;
  margin: 24px 15px;
  position: relative;
  .btn-view-history {
    border-radius: 0px 0px 0px 8px;
    background-color: $white-color;
    color: $indigo-blue;
    border: 1px solid $indigo-blue;
  }
  .btn-measure {
    border-radius: 0px 0px 8px 0px;
    background-color: $indigo-blue;
    color: $white-color;
  }
  button {
    border: none;
    width: 100%;
    padding: 6px 0px;
  }
  .card-container {
    padding: 14px 14px 18px 17px;
    .top-card-content {
      min-height: 38px;
      margin-bottom: 14px;
      .red {
        color: #f4939a;
      }
      .blue {
        color: #83cbd8;
      }
      .violet {
        color: #939df4;
      }
      .card-title {
        margin-left: 9px;
        font-size: 14px;
        line-height: 16px;
        border: none;
      }
      .week-count {
        font-size: 10px;
        line-height: 14px;
        text-align: right;
        border: none;
        .completeText {
          margin-left: 5px;
        }
        span {
          color: $success-color;
        }
      }
    }
    .card-label {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #868686;
    }
    .first > .card-label + .volume {
      display: flex;
      align-items: center;
    }
    .volume {
      .volume-quantity {
        color: #dedede;
        font-weight: 700;
        font-size: 24px;
        margin: 0 2px;
        line-height: 32px;
      }
      .volumeIncrease {
        color: #ee4055;
        font-weight: 700;
        font-size: 24px;
        margin: 0 2px;
        line-height: 32px;
      }
      .volumeDecrease {
        color: #43bc73;
        font-weight: 700;
        font-size: 24px;
        margin: 0 2px;
        line-height: 32px;
      }
      .volume-Change {
        color: #494e9d;
        font-weight: 700;
        font-size: 24px;
        margin: 0 2px;
        line-height: 32px;
      }
      .volume-label {
        color: #b1b1b1;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
      span > .downward {
        transform: rotate(180deg);
      }
    }
  }
  .open {
    overflow: hidden;
    transition: height 200ms;
  }
}

.connect-hc-btn {
  border: 1px solid;
  background: $indigo-blue;
  border-radius: 8px;
  color: $white-color;
  width: 90%;
  margin: 26px auto;
  justify-content: center;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.edit,
.delete {
  position: absolute;
  width: 100px;
  top: 0;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.edit {
  left: -100px;
  background-color: #73d587;
}

.delete {
  right: -100px;
  background-color: #f27e7e;
}

.body-weight-history-card {
  box-shadow: 8px 7px 25px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .date-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5a5a5a;
  }

  .cw-digit {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3d3d3d;
  }

  .cw-unit {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #c1c1c1;
  }

  .tw-p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #cfcfcf;
  }

  .tw-digit {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #cfcfcf;
  }
}

.no-record-found-history {
  height: calc(100vh - 244px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #313131;
  }
}

.blood-glucose-info {
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #3d3d3d;
    margin-bottom: 16px;
  }

  .info-card {
    border-radius: 8px;
    padding: 12px 24px;
    border: 1px solid $light-grey;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;

    .span-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .span-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3d3d3d;
    }

    .span-sub-label {
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #3d3d3d;
    }

    .low {
      color: #f6a400;
    }

    .normal {
      color: #69d192;
    }

    .high,
    .elevated,
    .emergency {
      color: #ff4e4e;
    }
  }
}

.confirm-delete-text {
  padding: 0 3rem;
  text-align: center;
}

.confirm-delete-buttons {
  display: flex;
  font-weight: 700;
  padding: 2rem 3rem 1rem;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  gap: 0.75rem;

  .confirm-delete,
  .cancel-button {
    border-radius: 4px;
    text-align: center;
    width: 100%;
    padding: 0.5rem;
  }
  .confirm-delete {
    background-color: $indigo-blue;
    color: $white-color;
  }
  .cancel-button {
    border: 1px solid $indigo-blue;
    color: $indigo-blue;
  }
}
