.task-card {
  background-color: $lavender-color;
  padding: 16px 0px;
  border: 1px solid $lavender-color;
  border-radius: 8px;
  margin: 20px 16px;

  .task-card-icon {
    background-color: $dull-lavender-color;
  }

  button {
    background-color: $shade-of-cyan-color;
    color: $white-color;
    border-radius: 30px;
    padding: 6px 12px;
    border: none;
  }
}
