.reports-section {
  height: 100vh;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );

  .reports-page-section {
    overflow: auto;
    height: calc(100vh - 178px);
  }
}

.reports-page-header {
  height: 115px;
  width: flex;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  position: relative;

  .reports-page-text {
    top: 76px;
    left: 48px;
    line-height: 24px;
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #313131;
  }
}

.logs {
  width: 343px;
  height: 181px;
  top: 129px;
  left: 16px;
  display: flex;
  padding: 5px;
  gap: 8px;
}

.view-log-text {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  right: 0;
  width: 100px;
  height: 24px;
  top: 78px;
  color: #494e9d;
}

.columnset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}

.flareup_item {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 12px;
}

.flareup_key {
  font-weight: 700;
}

.question-tag {
  font-family: Nunito Sans;
  font-size: 17px;
  padding: 5px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  width: flex;
  gap: 8px;
  color: #838383;
}

.answers {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 40px;
}
.answers_item {
  background-color: #494e9d;
  padding: 4px 12px;
  width: 100%;
  border-radius: 4px;
  margin-right: 4px;
  color: white;
  margin-top: -30px;
  display: flex;
  flex-direction: row;
  span {
    margin-left: 6px;
    font-weight: bold;
  }
}
.form-control {
  color: #838383;
  border: 1px solid;
  border-radius: 4px;
  gap: 8px;
  padding: 5px;
  width: 100%;
  &:focus {
    box-shadow: none;
  }
  margin-bottom: 40px;
}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    input {
      width: inherit;
      padding: 5px;
      gap: 8px;
      border-radius: 4px;
      border: 1px;
    }
  }
}

.date-picker {
  width: 100%;
}

.page-tag {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}

.footer-button {
  padding: 15px;
  width: 100%;
  bottom: 0;
}

.report-bottom-button {
  height: 50px;
  width: 100%;
  padding: 5px;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  border: 1px solid;
  border-radius: 6px;
}
.disabled {
  background-color: #adadad;
  color: #fff;
}

.enabled {
  background-color: #494e9d;
  color: #fff;
}

.page-report-questions {
  height: 100%;
  margin: 10px;
  width: Fixed (335px);
  height: Fixed (40px);
  top: 282px;
  left: 20px;
  padding: 8px, 16px, 8px, 8px;
  border-radius: 6px;
  border: 1px;
  gap: 8px;
  color: #d6d6d6;
}

.back-arrow-report {
  top: 71px;
  left: 14px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition-duration: 0.8s;
}

.alignment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.data-empty-report-symptoms {
  display: block;
  width: 40%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.question-tag-css {
  gap: 20px;
}
.form-control {
  margin-bottom: 40px;
}

.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.no-data-found {
  text-align: center;
  font-weight: bold;
}
.flare-up-log-section{
.flare-up-log-card {
  display: grid;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 10px;
}

.log-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 0 8px 0;
}
}
