.speedometer {
  margin: 32px 16px;
  border: 1px solid #e3e4f9;
  border-radius: 16px;
  padding: 16px 20px;
  text-align: center;
}

.green-dot,
.orange-dot,
.red-dot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}

.green-dot,
.Green-btn,
.Optimal-btn {
  background-color: #6cc891;
}

.orange-dot,
.Orange-btn,
.Sub-Optimal-btn {
  background-color: #feaa2c;
}

.red-dot,
.Red-btn,
.Alert-btn {
  background-color: #fd8080;
}

.Gray-btn {
  background-color: #d9d9d9;
}

.Green,
.Optimal {
  color: #43bc73;
}

.Red,
.Alert {
  color: #fd8080;
}

.Orange,
.Sub-Optimal {
  color: #ffb13f;
}

.Gray {
  color: #d9d9d9 !important;
}

.health-parameter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.health-parameter-card {
  border: 1px solid #eeeeee;
  box-shadow: 8px 7px 25px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 12px;

  .title {
    color: #51525c;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .date {
    color: #a7a7a7;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 0;
  }

  .btn-hp {
    border-radius: 20px;
    color: #ffffff;
    border: none;
    font-size: 10px;
    font-weight: 800;
    padding: 4px 12px;
    margin-top: 12px;
  }
}

.call-your-health-coach {
  border: 1px solid #ebe0f9;
  box-shadow: -1px -1px 20px 1px rgba(49, 49, 49, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  position: relative;

  .title {
    color: #313131;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .sub-title {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #6f6f6f;
    margin-bottom: 16px;
  }

  .schedule {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #494f9d;
    margin-bottom: 0;

    img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }

  .user-icon {
    position: absolute;
    right: 0;
    height: 100%;
    transform: translate(2px, -16px);
  }
}

.based-on-health-card {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 14px;
  position: relative;

  .bg {
    position: absolute;
    left: 0;
  }

  .image {
    width: 32px;
    z-index: 99;
  }

  .innner {
    color: #ef2b2b;
  }
}

.speedometer {
  .outer {
    position: absolute;
    top: 25%;
    left: 15%;
    width: 70%;
  }
}

.is-orange {
  position: absolute;
  top: 2%;
  left: 45%;
  transform: rotate(-18deg);
}

.is-red {
  position: absolute;
  left: 12%;
  top: 40%;
  transform: rotate(-80deg);
}

.is-green {
  position: absolute;
  top: 40%;
  transform: rotate(34deg);
  right: 12.5%;
}
