 
.all-programs-header {
  height: 124px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  position: relative;
  .all-programs-text {
    position: absolute;
    top: 76px;
    left: 48px;
    line-height: 24px;
  }
}
.program-card {
  width: 100%;
  height: 264px;
  background: #fff4e3;
  border-radius: 8px;
  position: relative;
  margin-top: -10px;
  padding: 15px;
  .program-card-content {
    width: 100%;
    justify-content: space-between;
  }
  .program-card-text {
    line-height: 26px;
    color: #313131;
  }
  .sub-text {
    color: #000000;
    line-height: 16px;
    width: 267px;
    height: 35px;
  }
  .program-card-card {
    margin-top: 14px;
  }
}

.active-card {
  width: 100%;
  height: 153px;
  background: linear-gradient(
    70.25deg,
    #73cbbf 2.71%,
    #ab99e8 81.47%,
    #f4939a 122.85%
  );
  position: relative;
  justify-content: space-between;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  .active-card-text {
    color: #ffff;
    padding: 16px;
  }
  .active-card-sub-text {
    height: 48px;
    line-height: 16px;
  }
  .active-card-footer {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .price-footer {
    margin-right: 10px;
  }
  .price-footer span {
    color: white;
  }
  .price-footer :nth-child(2) {
    text-decoration-line: line-through;
    color: rgba(255, 255, 255, 0.5);
  }
}

.recommend-program {
  background: linear-gradient(98.1deg, #e9fffc 8.51%, #dcdff1 88.61%);
  height: auto;
  border-radius: 10px;
  position: relative;
  justify-content: space-between;
  border-radius: 10px;
  margin: 16px;
  flex-direction: column;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  .recommend-program-text {
    color: #494e9d;
    padding: 15px;
    width: 100%;
  }
  .recommend-program-sub-text {
    height: auto;
    line-height: 16px;
    color: #313131;
  }
  .recommend-program-logo {
    position: relative;
  }
  .recommend-program-footer {
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 30px;
  }

  .footer-text {
    display: flex;
    align-items: center;
    height: 23px;
  }
}
.sponser-footer {
  width: 60%;
  height: 29px;
  background: rgba(220, 223, 241, 0.4);
  border-top-right-radius: 50px;
  display: flex;
  align-items: center;
}
.footer-logo {
  margin-left: 5px;
}
.footer-item {
  padding-top: 3px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.price-icon {
  margin-right: 10px;
}
.price-footer {
  margin-right: 10px;
}
.price-footer span {
  color: #494e9d;
}
.price-footer :nth-child(2) {
  text-decoration-line: line-through;
  color: rgba(73, 78, 157, 0.5);
  margin-left: 3px;
}
.price-footer :nth-child(3) {
  margin-left: 3px;
}

.card-logo {
  position: relative;
  display: flex;
  justify-content: end;
  width: 48%;
}
.card-logo :nth-child(1) {
  border-top-right-radius: 10px;
  opacity: 2.2;
  mix-blend-mode: multiply;
  width: 85px;
  height: 100px;
}
.card-logo :nth-child(2) {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 22px;
  top: 21px;
}
.partner-icons {
  padding: 0px 5px 0px 5px;
}
.partner-icons :nth-child(1) {
  padding: 0px 5px 0px 5px;
}

.program-overview-section{
  height: 100vh;
.program-details-section{
  overflow: auto;
  height: calc(100vh - 178px);
}
.program-description{
 position: relative;
 width: 100%;
height: 200px;
}
.background-image{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
  filter: brightness(0.5);
}
.program-information {
  color: $white-color;
  padding: 20px;
}
.program-pricetag{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 140px;
  height: 28px;
  margin: 16px;
  background: #EEEAFA;
border-radius: 16px;
}
.program-sponser{
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-around;
  width: 196px;
  height: 20px;
  bottom: 0px;
  background: linear-gradient(98.1deg, #E9FFFC 8.51%, #DCDFF1 88.61%);
border-radius: 0px 14px 14px 0px;
}
.benefit-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: linear-gradient(98.1deg, #E9FFFC 8.51%, #DCDFF1 88.61%);
}
.benefit-icon img {
  mix-blend-mode: multiply;
  width: 25px;
  height: 25px;
}
.benefit-title{
  width: 220px;
  margin-left: 15px;
}
.line-border{
  border: 0.5px solid #E4E4E4;
  margin: 12px 30px 0px 30px;
}
.elegibilty-list{
  margin: 16px;
}
.elegibilty-list img{
  margin-top: -6px;
}
.elegibilty-list :nth-child(2){
  margin-left: 8px;
}
.benefit-outcome-section{
  margin: 0px 15px 0px 15px;
}
.benefit-outcome-card{
background: linear-gradient(177.23deg, #A862DE -13.49%, #0C1475 109.75%);
box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
border-radius: 8px;
}
.benefit-card-icon{
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;

}
.outcome-card-heading{
  color: white;
  width: 60%;
}
.f-Q-section{
  margin: 32px 0px 50px 0px;
}
.f-q-list{
  height: 58px;
  background: linear-gradient(98.1deg, #E9FFFC 8.51%, #DCDFF1 88.61%);
  display: flex;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
    align-items: center;
    border-radius: 8px;
}
.answer-section{
  background: #FFFFFF;
border-radius: 8px;
margin-left: 15px;
margin-right: 15px;
filter: drop-shadow(-1px 1px 6px rgba(0, 0, 0, 0.05));
}
.answer-section p{
  text-align: justify;
    padding: 0px 9px 0px;
}
.expand-collapse-icon{
  width: 20px;
  height: 20px;
  margin: 10px;
}
.enroll-program-btn{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50px;
  background-color: $indigo-blue;
  color: $white-color;
  border-radius: 6px;
  border: 1px solid $indigo-blue;
}
.enroll-program-btn:disabled{
  opacity: 0.5;
}
.benefit-modal-section{
  display: flex;
  position: relative;
}
.benefit-modal-section p{
  text-align: center;
  padding-bottom: 36px;
}
.benefit-modal-section img{
  width: 100%;
  height: 371px;
  flex-shrink: 0;
  border-radius: 30px;
  margin-bottom: 36px;
  z-index: -1;
  filter: brightness(0.5);
  padding: 15px;
}
.benefit-modal-description{
  position: absolute;
  bottom: 0px;
  color: $white-color;
}
.benefit-modal-description p{
  text-align: start;
  padding: 0px 30px 60px 30px;
}
 .slick-slide img {
  display: initial;
}
.slick-prev,
.slick-next {
  display: none !important;
}
}


