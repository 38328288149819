.privacy-policy-container {
  background-color: #fcf9f4;
  padding: 20px;
  color: #14453d;
  .privacy-heading {
    font-size: 52px;
    font-weight: 600;
  }
  .content_container {
    margin: 30px 0px;
    a {
      padding: 0px 5px;
    }
    span {
      padding: 0px 5px;
      font-weight: 900;
    }
    table {
      border: 1px solid black;
      td {
        border: 1px solid black;
        padding: 20px 5px;
        ol > li {
          margin-bottom: 4px;
        }
      }
    }
  }
  ul {
    margin: 20px;
    li {
      list-style: disc;
    }
  }
}
