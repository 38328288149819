* {
  overscroll-behavior-y: contain;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  color: $secondary-black;
  margin: 0;
  padding: 0;
}

iframe {
  display: block;
  height: 100vh;
  width: 100vw;
}

ul {
  list-style: none;
  padding-left: 0;
}

p {
  margin-bottom: 0;
}

/*Typography Common Font Size*/
.f-10 {
  font-size: 10px;
}

.f-11 {
  font-size: 11px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-17 {
  font-size: 17px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-32 {
  font-size: 32px;
}

// Float Left or right
.float-right {
  float: right;
}

.float-left {
  float: left;
}

//Line Height
.line-12 {
  line-height: 12px;
}

.line-14 {
  line-height: 14px;
}

.line-15 {
  line-height: 15px;
}

.line-16 {
  line-height: 16px;
}

.line-18 {
  line-height: 18px;
}

.line-19 {
  line-height: 19px;
}

.line-22 {
  line-height: 22px;
}

.line-24 {
  line-height: 24px;
}

.line-25 {
  line-height: 25px;
}

.line-28 {
  line-height: 28px;
}

.line-43 {
  line-height: 43px;
}

//Font-color
.charcoal-grey {
  color: $charcoal-grey-color;
}

.dark-gray {
  color: $dark-gray;
}

.light-gray {
  color: $light-grey;
}

.orange-color {
  color: $progress-color;
}

.indigo-blue {
  color: $indigo-blue;
}

.error-color {
  color: $error-color;
}

.red-color {
  color: #f24d4d;
}

.success-color {
  color: $success-color;
}

//Font weight
.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

//Margin
.mt-38 {
  margin-top: 38px;
}

//Toast CSS
.Toastify__close-button {
  display: none;
}

.Toastify__toast-body {
  color: $secondary-black;
  font-weight: 400px;
}

//toast container
.Toastify__toast {
  color: $secondary-black;
  border-radius: 4px;
  min-height: 26px !important;
  min-width: 344px !important;
  margin-bottom: 6%;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 4px;
    min-height: 26px !important;
    width: 92% !important;
    margin: 16px auto !important;
  }
}

//Z-index
.z-index-1 {
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.ws-nowrap {
  white-space: nowrap;
}

.chat-header-back-icon {
  left: 16px;
  top: 24px;
}

.chat-container {
  min-height: 100vh;
  position: relative;
}

//modal styling
.react-datepicker {
  font-family: 'Nunito Sans', sans-serif;
  color: $secondary-black;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;

  .react-datepicker__month-container {
    width: 100%;
    height: 325px;
    // background-color: #848ad4 !important;

    .react-datepicker__header {
      border-radius: 0;
      background-color: $white-color;
      border-bottom: none;
      padding: 0;
      // background-color: #494E9D !important; 

      .react-datepicker__day-names {
        @extend .fw-600;
        @extend .f-12;
        @extend .line-18;
        margin-bottom: 22px;
        display: flex;
        justify-content: space-between;

        .react-datepicker__day-name {
          color: $secondary-black;
        }
      }
    }

    .react-datepicker__month {
      .react-datepicker__week {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 12px;

        .react-datepicker__day,
        .react-datepicker__day--today {
          @extend .f-12;
          @extend .line-18;
          margin: 0;
          color: $secondary-black;
          min-height: 26px;
          border-radius: 50%;
          padding: 3px;
        }

        .react-datepicker__day--disabled {
          color: $light-grey;
        }

        .react-datepicker__day--today {
          background-color: $white-color;
          border-radius: 50%;
        }

        .react-datepicker__day--selected {
          color: $white-color;
          @extend .fw-600;
          border: 1px solid $indigo-blue;
          border-radius: 50%;
          background-color: $indigo-blue;
          padding: 3px;
        }

      }
    }
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $white-color;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
  color: transparent !important;
  pointer-events: none;
}

.slick-bar {
  width: 20px;
  border: 3px solid #494e9d;
  background: #494e9d;
  margin-top: 10px;
  opacity: 0.2;
  border-radius: 10px;
}

.slick-active .slick-bar {
  opacity: 0.8;
  width: 25px;
}

.user-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upcoming-header {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

button:focus {
  box-shadow: none !important;
}

.modal-body {
  overflow-wrap: break-word;
}

div[data-paste-element='MODAL_HEADER'] {
  border: none !important;
}

div[data-paste-element='MODAL_FOOTER'] {
  border: none !important;
}

button[data-paste-element='MODAL_HEADER_CLOSE_BUTTON'] {
  display: none !important;
}

.back-arrow {
  width: 8.59px;
  height: 15px;
  color: $indigo-blue;
}

.back-arrow-container {
  position: absolute;
  top: 71px;
  left: 14px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition-duration: 0.8s;
}

.back-arrow-container:active {
  background-color: #e3f2fd;
  transition: all 0.3s;
}

.arrow-container {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  transition-duration: 0.8s;
  border: none;
  background: transparent;
}

.arrow-container:active {
  background-color: #afb4ff;
  transition: all 0.3s;
}

// Rotate 180 deg
.rotate-180 {
  transform: rotate(180deg);
}

.surey-modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: $white-color;
  flex-direction: column;
  align-items: end;
}

.modalclose {
  float: right;
  position: relative;
  top: 0;
}
