.dashboard {
  overflow-x: hidden;

  .react-datepicker {
    color: $secondary-black;
    border: none;
    border-radius: 0;
    display: block;
    width: 100%;

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker__header {
        border-radius: 0;
        background-color: $white-color;
        border-bottom: none;
        padding: 0;

        .react-datepicker__day-names {
          @extend .fw-600;
          @extend .f-12;
          @extend .line-18;
          margin-bottom: 22px;
          display: flex;
          justify-content: space-between;

          .react-datepicker__day-name {
            color: $secondary-black;
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;

          .react-datepicker__day,
          .react-datepicker__day--today {
            @extend .f-12;
            @extend .line-18;
            margin: 0;
            color: $secondary-black;
            min-height: 26px;
          }

          .react-datepicker__day--disabled {
            color: $light-grey;
          }

          .react-datepicker__day--today {
            background-color: $white-color;
          }

          .react-datepicker__day--selected {
            color: $white-color;
            @extend .fw-600;
            border: 1px solid $indigo-blue;
            border-radius: 50%;
            background-color: $indigo-blue;
            padding: 3px;
          }
        }
      }
    }
  }

  .select-date-next-btn {
    border: 1px solid $indigo-blue;
    border-radius: 8px;
    padding: 16px;
  }

  .input-group {
    .input-group-text {
      background-color: $unicorn-silver-color;
      border: 1px solid $unicorn-silver-color;
      border-radius: 4px;
    }

    .form-control {
      border: 1px solid $unicorn-silver-color;
      border-radius: 4px;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .callback-cards-slider {
    transform: translate(0, -50%);
  }

  .program-section {
    margin: -10px 16px 0px 16px;
  }
}

.report-button {
  padding: 2px;
  width: 100%;
  font-size: 12px;
  float: right;
  position: relative;
  overflow: hidden;
  color: #e00e0e;
  border-radius: 6px;
  align-items: center;
}

.calendar-card {
  background-color: #f6f7ff;
  border-radius: 10px;

  .circle-image-1 {
    position: absolute;
    z-index: 0;
    left: 0;
    width: 88px;
    height: 44px;
    bottom: 0;
    border-radius: 0 0 0 10px;
  }

  .circle-image-2 {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
    width: 61px;
    height: 27px;
    border-radius: 0 10px 0 0;
  }

  .schedule-calendar-date {
    width: 160px;
  }
}

.Toastify__toast-theme--dark {
  .Toastify__toast-body {
    color: $white-color;
    font-weight: 400px;
    font-family: 'Nunito Sans';
  }
}

.footer {
  text-align: center;
  background: linear-gradient(
    98.1deg,
    rgba(233, 255, 252, 0.5) 8.51%,
    rgba(220, 223, 241, 0.5) 88.61%
  );
  bottom: 0;
}

.footer-header {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #5a5a5a;
  padding: 24px 47px 0px 47px;
}

.footer-content {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #5a5a5a;
  padding: 15px 35px 0px 35px;
}

.footer-border-line {
  border: 1px solid #e3e4f9;
  display: block;
  margin: 10px 21px auto;
}

.program-card-card {
  margin: -10px 0px 0px 16px;
}
.report-flarup {
  display: flex;
  cursor: pointer;
}
.report-image {
  align-items: center;
}
