.stepper-box {
  height: 80px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 0px 20px;
  .status-progress {
    display: flex;
    align-items: center;
    width: 100%;
    align-items: center;
    background-color: $lavender-color;
    height: 3px;
  }
  .progress-bar {
    width: 100%;
    background-color: $success-color;
    height: 3px;
  }
  .initial {
    width: 40%;
  }
  .final {
    width: 83%;
    transition: all 0.8s ease-in-out;
  }
  .status-email {
    margin-left: -13px;
  }
  .status-call {
    margin-right: -13px;
  }
  .status-stepper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: -23px;
  }
}
