.chat-header {
  height: 100px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  position: relative;
  .back-arrow {
    width: 8.59px;
    height: 15px;
    position: absolute;
    top: 54.68px;
    left: 25px;
    color: $indigo-blue;
  }
  .chat-text {
    position: absolute;
    height: 24px;
    top: 50px;
    left: 49px;
    line-height: 24px;
  }
}

.specilist-card {
  min-height: 126px;
  border: 1px solid #ebe0f9;
  box-shadow: -1px -1px 20px 1px rgba(49, 49, 49, 0.1);
  border-radius: 8px;

  .hc-name {
    font-family: Nunito Sans;
  }
  .subheading {
    color: #6f6f6f;
    font-family: Nunito Sans;
  }

  .doctor-img {
    border-radius: 100px 8px 8px 100px;
    background: linear-gradient(
      to right,
      $light-shade-cyan-color,
      $gainsboro-color
    );
  }
  .btn {
    border-radius: 30px;
    background-color: $shade-of-cyan-color;
    color: $white-color;
    font-family: Nunito Sans;
    padding: 3px 9px 3px 7px;
    margin-bottom: 14px;
  }
}
