.callback-request-card {
  background: #ffffff;
  border: 0.75px solid #eeeeee;
  box-shadow: 4px 4px 20px 3px rgba(49, 49, 49, 0.12);
  border-radius: 8px;
  height: 85px;

  .right-border {
    border: 1px solid #e3e4f9;
    height: 54px;
  }

  .call-text {
    margin-bottom: 2px;
    white-space: nowrap;
  }

  .w-auto {
    width: auto !important;
  }
}
