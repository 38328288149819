.mini-card {
  border: none;
  position: relative;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  div > p:nth-child(1) {
    white-space: nowrap;
  }
}
