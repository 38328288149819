.rc-time-picker {
  // dis
}

.rc-time-picker-panel-select-option-selected {
  background-color: #f6f6f8;
  font-weight: normal;
}

.rc-time-picker-clear,
.rc-time-picker-clear-icon:after {
  font-size: 15px;
}

.rc-time-picker-panel-inner {
  padding: 0px 0px 0px 20px;
  position: fixed;
  bottom: 0px;
  border: none;
  box-shadow: none;
}

.rc-time-picker-input {
  width: 85px;
  height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  display: none;
}

.rc-time-picker-panel-input-wrap {
  display: none;
}

.rc-time-picker-panel-select {
  padding-right: 20px;
  text-transform: uppercase;
  max-height: 210px;
  padding: 0;
  width: 60px;

  ul {
    li {
      padding: 12px;
      height: auto;
      text-align: center;
    }
  }
}

.rc-time-picker-panel-select,
.rc-time-picker-input,
.rc-time-picker-panel-input {
  font-family: 'Consolas', sans-serif;
  font-size: 16px;
  color: $indigo-blue;
  cursor: pointer;
  border: none;
  outline: none;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.time-container {
  padding-left: 10%;
  gap: 15px;
}
