/* The Modal (background) */
.modal-footer,
.modal {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

//token expired modal
.modal {
  .modal-content {
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border: none;
    padding: 36px;
    border-radius: 36px;
  }

  button {
    width: 50%;
    margin: 14px auto;
    color: $white-color;
    background-color: $indigo-blue;
    border: none;
    outline: none;
    padding: 8px;
    border-radius: 8px;
  }
}

.modal-footer {
  /* Modal Content */
  .modal-content {
    background-color: $white-color;
    padding: 40px 16px 18px 16px;
    position: absolute;
    bottom: 0;
    border: 1px solid $white-color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .close {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

/* Success Modal popup  */
.success-modal-footer {
  .modal-content {
    background-color: $white-color;
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border: none;
  }
}
.left-arrow-chat {
  width: 18.41px;
  height: 15.41px;
}
.chat-header {
  height: 64px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  padding: 0 16px;
}

.appointment-success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white-color;
  z-index: 10;
}
