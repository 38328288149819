.challenge {
  header {
    background: linear-gradient(
      to right,
      $light-shade-cyan-color,
      $gainsboro-color
    );
    padding: 84px 24px 54px;
  }

  .tab {
    border: 1px solid $lavender-color;
    border-radius: 100px;
    margin-top: -20px;
    background-color: $white-color;

    li {
      padding: 12px 0;
      width: 33.33%;
      text-align: center;
    }

    .active {
      background-color: $indigo-blue;
      border-radius: 200px;
      color: $white-color;
    }
  }

  .cards-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;

    .card {
      padding: 12px;
      background-color: $white-color;
      border-bottom: 5px solid $unicorn-silver-color;
      box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      border-image-slice: 1;
      border-left: 0;
      border-top: 0;
      border-right: 0;
    }
  }

  .modal-img {
    height: 142px;
    object-fit: cover;
    border-radius: 8px 8px 0px 0px;
  }

  .round {
    position: relative;
  }

  .round label {
    background-color: $white-color;
    border: 1px solid $indigo-blue;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
  }

  // .round .future {
  //     background-color: #F9F9F9;
  //     border: 1px solid #F0F0F0;
  // }

  .round .skipped {
    background-color: #f9e0e0;
    border: 1px solid #f4939a;
  }

  .round .future-label {
    color: #7d7d7d;
  }

  .round .skipped-label {
    color: #f4939a;
  }

  .round .checked-label {
    color: #43bc73;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type='checkbox'] {
    visibility: hidden;
  }

  .round input[type='checkbox']:checked + label {
    background-color: #43bc73;
    border-color: #43bc73;
  }

  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }

  .active-challenge-card {
    background: #ffffff;
    border-bottom: 6px solid #d9d9d9;
    box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 12px;
    border-image-slice: 1;

    img {
      width: 100%;
      height: 124px;
      border-radius: 4px;
      object-fit: cover;
    }

    ul {
      list-style: disc;
      margin-left: 16px;
    }

    .button-footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 18px;

      .later-btn,
      .today-btn {
        border-radius: 4px;
        padding: 8px 12px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }

      .later-btn {
        background: #ffffff;
        border: 1px solid #494e9d;
        color: #494e9d;
      }

      .today-btn {
        background: #494e9d;
        border: 1px solid #494e9d;
        color: #ffffff;
      }
    }
  }
}
