.flare-up-form-section{
    height: calc(100vh - 172px);
    overflow: scroll;
}
.selected-symptoms {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    margin-left: 6px;
}
.symptom-tag{
    background-color: #494e9d;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 6px;
    gap: 10px;
    color: #ffff;
}
.flare-up-form-section{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px;
}
.flare-up-input{
    width: 100%;
    margin-top: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    height: 40px;
    padding: 5px;
}
.symptom-select{
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
    height: 40px;
}

.flare-up-report-btn {
    width: 100%;
    border: 1px solid $indigo-blue;
    background: $indigo-blue;
    padding: 16px;
    color: $white-color;
    width: 100%;
}
.flare-up-report-btn-disable{
    width: 100%;
    border: 1px solid $indigo-blue;
    background: $indigo-blue;
    padding: 16px;
    color: $white-color;
    opacity: 0.5;
}
.flare-up-form-section p{
    font-size: 17px;
    font-weight: 400;
    color: #838383;
    font-family: 'Nunito Sans';
}
.slider-range{
    display: flex;
    align-items: baseline;
    gap: 15px;
}
.slider-range span {
    border-radius: 50%;
    background-color:  #0567f9;
    width: 25px;
    color: #ffff;
    text-align: center;
}