.confirm-appointment-btn {
  border: 1px solid $indigo-blue;
  background: $white-color;
  border-radius: 8px;
  padding: 16px;
  color: $indigo-blue;
  margin-top: 2rem;
  width: 100%;
}
.confirm-btn-disable {
  border: 1px solid $light-grey;
  background: $white-color;
  border-radius: 8px;
  padding: 16px;
  color: $light-grey;
}
.time-slot {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  :first-child {
    width: 50%;
  }

  :nth-child(2) {
    width: 48%;
  }

  .time-slot-btn {
    border: 1px solid $unicorn-silver-color;
    padding: 8px 8px;
    border-radius: 4px;
    background-color: $white-color;
    color: $secondary-black !important;
  }
}
.select-date-next-btn {
  border: 1px solid $indigo-blue;
  border-radius: 8px;
  padding: 16px;
}

.time-span {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  li {
    border: 1px solid $indigo-blue;
    border-radius: 8px;
    padding: 16px 0px;
    color: $indigo-blue;
  }

  .active {
    background-color: $indigo-blue;
    color: $white-color;
    text-align: center;
  }

  .disable {
    border: 1px solid $light-grey;
    background: $white-color;
    color: $light-grey;
  }
}
.input-group {
  .input-group-text {
    background-color: $unicorn-silver-color;
    border: 1px solid $unicorn-silver-color;
    border-radius: 4px;
  }

  .form-control {
    border: 1px solid $unicorn-silver-color;
    border-radius: 4px;
    margin-bottom: 0px;

    &:focus {
      box-shadow: none;
    }
  }
}
