.header {
  height: 178px;
  background: linear-gradient(98.1deg, #e9fffc 8.51%, #dcdff1 88.61%);
  padding: 0 16px;

  .title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
    color: $indigo-blue;
  }

  // span {
  //   line-height: 1.2;
  // }

  // .right-side {
  //   img {
  //     height: 40px;
  //     width: 40px;
  //   }
  // }
}

.dash-header {
  .health-passport {
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    position: relative;
    height: 36px;
    width: 36px;
  }

  .Blue {
    border-color: #6064a9;
    background-color: #6064a9;
  }

  .Sub-Optimal {
    border-color: #fecb7f;
    background-color: #fecb7f;
  }

  .Optimal {
    border-color: #43bc73;
    background-color: #43bc73;
  }

  .Alert {
    border-color: #eb4747;
    background-color: #eb4747;
  }

  .exclamation-mark {
    height: 16px !important;
    width: 16px !important;
    position: absolute;
    z-index: 9;
    right: -4px;
    top: -4px;
  }

  .border-normal {
    border-color: #43bc73;
  }

  .border-risk {
    border-color: #fecb7f;
  }

  .border-alert {
    border-color: #eb4747;
  }

  .border-pending {
    border-color: #6064a9;
  }
}

.header-modal {
  hr {
    margin: 12px 0 20px 0;
    color: #c7c7c7;
  }
  p {
    color: $indigo-blue;
    line-height: 16px;
    font-weight: 600;
    font-size: 16px;
  }
}
.active-program-badge {
  width: fit-content;
  height: 21px;
  margin-bottom: 18px;
  margin-top: 10px;
  background: linear-gradient(
    91.89deg,
    rgba(227, 227, 227, 0.49) 17.94%,
    rgba(255, 255, 255, 0.7) 63.38%,
    rgba(234, 234, 234, 0.49) 93.15%
  );
  border: 0.75px solid $white-color;
  box-shadow: -4px -4px 15px -4px $light-grey;
  border-radius: 16px;
}
.active-program-badge img {
  width: 20px;
  height: 20px;
}
