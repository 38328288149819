//new chat design

.specilistCard {
  min-height: 75px;
  border: 1px solid #ededed;
  box-shadow: 1px 1px 10px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0px 13px;

  .hc-name {
    font-family: Nunito Sans;
  }
  .subheading {
    color: #6f6f6f;
    font-family: Nunito Sans;
  }
  .image-container {
    height: 54px;
    width: 54px;
    .doctor {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }

  .btn {
    border-radius: 30px;
    background-color: $shade-of-cyan-color;
    color: $white-color;
    font-family: Nunito Sans;
    padding: 3px 9px 3px 7px;
    margin-bottom: 14px;
  }
}
.chatHeader {
  height: 100px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  position: relative;
  .back-arrow {
    width: 8.59px;
    height: 15px;
    color: $indigo-blue;
  }
  .back-arrow-container {
    position: absolute;
    top: 71px;
    left: 14px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition-duration: 0.8s;
  }
  .back-arrow-container:active {
    background-color: #e3f2fd;
    transition: all 0.3s;
  }
  .chatText {
    position: absolute;
    width: 279px;
    height: 24px;
    top: 50px;
    left: 49px;
    line-height: 24px;
  }
}

.chatCount {
  width: 30px;
  height: 24px;
  border-radius: 92px;
  background-color: #eb4747;
  letter-spacing: 0.02em;
  color: $white-color;
}
.chat-information {
  color: #6f6f6f;
  font-family: Nunito Sans;
}
