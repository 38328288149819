$white-color: #ffffff;
$purple-color: #4a4f9e;
$indigo-blue: #494e9d;
$light-shade-cyan-color: #e9fffc;
$gainsboro-color: #dcdff1;
$secondary-black: #313131;
$dull-lavender-color: #ab98e8;
$lavender-color: #e3e4f9;
$charcoal-grey-color: #5a5a5a;
$shade-of-cyan-color: #59c4ba;
$dark-gray: #5a5a5a;
$unicorn-silver-color: #e8e8e8;
$light-grey: #cfcfcf;
$error-color: #c60813;
$success-color: #43bc73;
$progress-color: #feb343;
