.call-back-card{
    gap: 32px;
}
.loader-content{
    margin-top: -7px;
}
.calender-loader{
    background: $white-color;
    border: 0.75px solid $unicorn-silver-color;
    box-shadow: 4px 4px 20px 3px rgba(49, 49, 49, 0.12);
    border-radius: 8px;
    height: 85px;
}
.calender-loader-content {
    margin-left: 104px;
}
.activity-card-loader{
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.06);
    position: relative;
    min-height: 63px;
    margin-top: 20px;
    overflow: hidden;
}