.wrapper {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
  display: flex;

  .loading__anim {
    width: 350px;
  }
}
