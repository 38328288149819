.chat-button {
  position: fixed;
  right: 16px;
  bottom: 14%;
  z-index: 1;

  .notification-count {
    background-color: $error-color;
    top: 10px;
    right: 5px;
    border-radius: 6px;
    color: $white-color;
    padding: 2px 4px;
  }
}
