.track-vital-header {
  height: 199px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  position: relative;

  .back-arrow {
    width: 8.59px;
    height: 15px;
    position: absolute;
    top: 69px;
    left: 24px;
    color: $indigo-blue;
  }

  .vital-text {
    position: absolute;
    height: 24px;
    top: 64px;
    left: 48px;
    line-height: 24px;
  }

  .info-icon {
    top: 64px;
    position: absolute;
    right: 24px;
    cursor: pointer;
  }
}

.save-btn {
  width: 90%;
  margin: 0 16px 16px 16px;
  height: 50px;
  border-radius: 4px;
  background-color: $white-color;
  z-index: 1;
}

.disable-btn {
  border: 2px solid $light-grey;
  color: $light-grey;
}

.save-btn-enable {
  border: 2px solid $indigo-blue;
  color: $indigo-blue;
}

.main-container {
  .card-body {
    flex: 1;
    margin-top: -90px;
    padding: 24px;

    .card-content {
      border: 1px solid #e8e8e8;
      background-color: $white-color;
      border-radius: 10px;
      padding: 30px 16px;

      .capture-btn {
        border: none;
        background-color: transparent;
        color: $indigo-blue;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }

      .vital-input {
        width: 76px;
        border: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }

      .danger-input {
        width: 76px;
        color: red;
        border: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.cardData {
  border: 1px solid #e8e8e8;
  width: 90%;
  margin: -63px auto;
  z-index: 1;
  background-color: $white-color;
  border-radius: 10px;
  padding: 30px 16px;
  // overflow-y: scroll;

  .capture-btn {
    border: none;
    background-color: transparent;
    color: $indigo-blue;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .vital-input {
    width: 76px;
    border: none;
    outline: none;

    &:focus {
      box-shadow: none;
    }
  }

  .danger-input {
    width: 76px;
    color: red;
    border: none;
    outline: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.input-divider {
  margin: 20px 0px 16px 0px;
  color: #e8e8e8;
}

.select-date-next-btn {
  border: 1px solid $indigo-blue;
  border-radius: 8px;
  padding: 16px;
}

.react-datepicker__day--today {
  background-color: $white-color;
  border: 1px solid $success-color;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.measured-btn {
  background-color: $white-color;
  border: 1px solid #e8e8e8;
  padding: 8px 15px;
  gap: 10px;
  border-radius: 4px;
}

.selected-btn {
  background-color: #f8f8ff;
  border: 1px solid $indigo-blue;
  padding: 8px 15px;
  gap: 10px;
  border-radius: 4px;
}

//input label css
.label-field {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.done-btn {
  border: none;
  background: none;
  color: $indigo-blue;
  position: absolute;
  top: 21px;
  right: 18px;
  font-weight: 700;
}

.time-modal-date {
  font-size: 16px;
  color: $indigo-blue;
  font-weight: 700;
}

.result-btn {
  border: none;
  border-radius: 30px;
  padding: 8px 16px;
}

.result-btn-warning {
  background-color: $progress-color;
}

.result-btn-normal {
  background-color: $success-color;
}

.result-btn-error {
  background-color: $error-color;
}

.invalid-feedback > span > img {
  margin-right: 10px;
}
