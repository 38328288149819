.schedule-call-card {
  border: 1px solid $light-shade-cyan-color;
  border-radius: 10px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  font-family: Nunito Sans;

  .call-header {
    padding: 31px 0px 0px 0px;
    min-height: 24px;
  }
  .text-container {
    ul {
      list-style: disc;
    }
    .date-time-text {
      color: #494f9d;
    }
  }
  .schedule-logo {
    img {
      width: 22px;
      height: 22px;
    }
  }
}
.schedule-call-btn {
  border: 1px solid $indigo-blue;
  background: $white-color;
  border-radius: 8px;
  padding: 16px;
  color: $indigo-blue;
  width: 100%;
}
.schedule-btn-disable {
  border: 1px solid $light-grey;
  background: $white-color;
  border-radius: 8px;
  padding: 16px;
  color: $light-grey;
  width: 100%;
}
.task-card-container {
  margin: 24px 17px;
}
.content {
  width: 61%;
}
