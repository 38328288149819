.consultant-activity-card {
  padding-top: 7px;
}

.activity-icon {
  width: 63px;
}

.later-btn {
  border: 1px solid $indigo-blue;
  background: $white-color;
  border-radius: 4px;
  color: $indigo-blue;
  padding: 4px 20px 4px 20px !important;
}

.task-done-btn {
  border: 1px solid $indigo-blue;
  background: $indigo-blue;
  border-radius: 4px;
  color: $white-color;
  padding: 4px 20px 4px 20px !important;
}

.activity-completed-img {
  height: 15px;
  width: 15px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.status-completed-img {
  height: 18.33px;
  width: 18.33px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.activity-text {
  color: $success-color;
}

.status-completed {
  visibility: hidden;
}
