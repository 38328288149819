.gFit-header {
  height: 124px;
  background: linear-gradient(
    to right,
    $light-shade-cyan-color,
    $gainsboro-color
  );
  position: relative;
  .gFit-text {
    position: absolute;
    top: 52px;
    left: 48px;
    line-height: 24px;
  }
  .gFit-update-text {
    position: absolute;
    top: 80px;
    left: 47px;
    color: #9f9f9f;
  }
  .red {
    border: 1px solid #ffc6ca;
  }
  .blue {
    border: 1px solid #83cbd8;
  }
  .violet {
    border: 1px solid #939df4;
  }
}
.step-unit {
  color: $unicorn-silver-color;
  left: -13px;
  top: -2px;
}
.gfit-logo {
  padding-right: 20px;
}
