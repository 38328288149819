.home-container {
  background-color: #fcf9f4;
  .home-block {
    padding: 20px;
    .svass-logo {
      border-radius: 50%;
      width: 100%;
    }
    .login-btn {
      display: flex;
      justify-content: flex-end;
      button {
        background-color: #14453d;
        border-radius: 100px;
        padding: 6px 36px;
        border: none;
      }
    }
  }
  .subtext {
    padding: 20px;

    .subtext-content {
      font-size: 56px;
    }
    .content-img {
      border-radius: 10px;
    }
    .text-list {
      line-height: 1.3;
      .text-header {
        margin: 30px 0px;
      }
      .sub-text {
        line-height: 1.3;
      }
      .item-list {
        margin: 30px 20px;
        ul > li {
          list-style-type: disc !important;
          margin-bottom: 4px;
          font-weight: 600;
        }
      }
    }
    .login_button {
      background-color: #14453d;
      padding: 10px 25px;
      border: none;
      border-radius: 4px;
      color: #fff;
      font-weight: 700;
      font-size: 28px;
    }
    .good_health {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-top: 30px;
    }
  }
  .text-body {
    margin: 30px 0px;
  }
  .myhealth-title {
    line-height: 1.3;
    margin-top: 10px;
    font-weight: 600;
  }
  .footer {
    background-color: #494e9d;

    color: white;
  }
  .myhealth ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .links {
    padding: 30px 0px;
    font-weight: 600;
    font-size: 18px;
  }
  .footer_end {
    line-height: 1.6;
  }
}
