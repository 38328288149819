.enroll-program-card {
  box-shadow: 8px 7px 25px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 1px solid #ebe0f9;
  min-height: 63px;
  margin-top: 20px;

  .activity-content {
    margin-top: 10px;
    .activity-icon {
      width: 63px;
    }
  }
}
.mhi-card {
  border: 1px solid #ebe0f9;
  font-family: 'Nunito Sans';
  box-shadow: -1px -1px 20px 1px rgba(49, 49, 49, 0.1);
  border-radius: 8px;
  padding: 16px 7px 0px 16px;
  div > div > img {
    top: 70% !important;
  }
  .logo-container {
    height: 24px;
    width: 24px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .subtext {
    color: #999999;
    width: 80%;
  }
}
