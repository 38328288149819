.appointment-success {
  .section {
    position: relative;
    .image1,
    .image2,
    .image3 {
      width: 100%;
    }

    .image2 {
      position: absolute;
      top: 0;
      left: 0;
    }

    .image3 {
      position: absolute;
      top: 26px;
      left: 0;
    }

    h1 {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%);
      font-size: 64px;
    }
  }
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
