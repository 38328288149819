.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  div {
    border-radius: 12px;
    padding: 48px 0;
    box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
    width: 100%;
    margin: 0 auto;

    h1 {
      font-weight: 500;
      line-height: 1.5;
      padding-bottom: 12px;
    }

    button {
      background-color: $purple-color;
      padding: 12px 26px;
      border-radius: 4px;
      border: none;
      color: $white-color;
      font-weight: bold;
    }
  }
}
