.physical-activity-content {
  margin-top: 7px;

  .bg-image-1 {
    position: absolute;
    left: 0;
  }

  .bg-image-2 {
    position: absolute;
    left: 0;
  }

  .card-text {
    left: 77px;
  }
}

.activity-icon {
  width: 63px;
}

.progress {
  background: #d9d9d9;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  height: 5px;
}

.physical-activity-card {
  box-shadow: 8px 8px 25px rgba(0, 0, 0, 0.06);
  position: relative;
  min-height: 63px;
  margin-top: 20px;
  overflow: hidden;
  // position: relative;
}
